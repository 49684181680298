import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import loadable from '@loadable/component'

import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import './index.scss'

Sentry.init({
    dsn: "https://eb55946dadc64cdebbc9da05bcec0576@o4505431681007616.ingest.sentry.io/4505431724720128",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/dommap\.sibir\.tech\//],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const App = loadable(() => import('./App'))
const DomainView = loadable(() => import('./views/DomainView'))
const MonitorView = loadable(() => import('./views/MonitorView'))


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/domain/:domain",
        element: <DomainView />,
    },
    {
        path: "/monitor",
        element: <MonitorView />
    }
]);

root.render(
  // <React.StrictMode>
      <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
